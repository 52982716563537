import React from 'react'
import SEO from '~/components/seo'
import {
  Wrapper,
  Desc,
  Title,
  Container,
  Head,
  ListItem,
  List,
} from '../styles/staticPage'
import { EMAIL } from '../const'

const PrivacyPage = () => (
  <Wrapper>
    <SEO title="プライバシーポリシー" />
    <Head>プライバシーポリシー</Head>
    <Container>
      <Title>個人情報のお取り扱いについて</Title>
      <Desc>
        高天原は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
      </Desc>
    </Container>

    <Container>
      <Title>個人情報の管理</Title>
      <Desc>
        当店は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
      </Desc>
    </Container>

    <Container>
      <Title>個人情報の利用目的</Title>
      <Desc>
        お客さまからお預かりした個人情報は、当店からのご連絡や業務のご案内やご質問に対する回答として、電子メール（メールマガジン）や資料のご送付に利用いたします。
      </Desc>
    </Container>

    <Container>
      <Title>個人情報の第三者への開示・提供の禁止</Title>
      <Desc>
        当店は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
      </Desc>
      <List>
        <ListItem>お客さまの同意がある場合</ListItem>
        <ListItem>
          お客さまが希望されるサービスを行なうために当店が業務を委託する業者に対して開示する場合
        </ListItem>
        <ListItem>法令に基づき開示することが必要である場合</ListItem>
      </List>
    </Container>

    <Container>
      <Title>個人情報の安全対策</Title>
      <Desc>
        当店は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
      </Desc>
    </Container>

    <Container>
      <Title>ご本人の照会</Title>
      <Desc>
        お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
      </Desc>
    </Container>

    <Container>
      <Title>法令、規範の遵守と見直し</Title>
      <Desc>
        当店は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
      </Desc>
    </Container>

    <Container>
      <Title>お問い合せ</Title>
      <Desc>
        当店の個人情報の取扱に関するお問い合せは下記までご連絡ください。
        <br />
        <br />
        {EMAIL}
      </Desc>
    </Container>
  </Wrapper>
)

export default PrivacyPage
