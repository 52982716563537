import styled from '@emotion/styled'
import color from './color'

export const Wrapper = styled.div`
  padding: 0 12px;
  margin-bottom: 50px;
`

export const Head = styled.h1`
  font-size: 18px;
  text-align: center;
  color: ${color.BLACK};
  margin: 33px 0;
`

export const Container = styled.div`
  margin-bottom: 24px;
`
export const Title = styled.h2`
  font-weight: 700;
  font-size: 16px;
  color: ${color.BLACK};
`

export const Desc = styled.div`
  font-size: 15px;
  color: ${color.BLACK};
  margin-bottom: 16px;
`

export const List = styled.div`
  padding: 0 12px;
`

export const ListItem = styled.div`
  font-size: 15px;
  color: ${color.BLACK};

  &::before {
    content: '・';
  }
`
